:root{
    --humber-primary:#131414;
    --humber-secondary:#8A0000;
    --humber-tertiary:#FAF1BB;
    --sidebar-background:#1C1802;
    --humber-link:#FC6464;
    --humber-golden:#F5E697;
    --humber-dark:#161616;
    --humber-dark-brown:#151010;
    --border-color:#F5E697;
    --border-color-light:#ffffff;
    --humber-light:#b9b5b5;
    --humber-black:#000000;
    --gradient-primary:linear-gradient(180deg, #FCF5D3 0%, #643A08 100%);
    --gradient-secondary:linear-gradient(183deg, #887C37 7.47%, #504400 58.25%);
    --gradient-tertiary: linear-gradient(180deg, #D9802B 0%, #000000 100%);
    
  }